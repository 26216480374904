<template>
	<div class="ad-section" :class="emptyZoneClass + ' ' +adtype+' '+bgclass" v-if="zoneId > 0">
		<div class="ad-holder" :class="adholderclass" ref="holder">
			<ins :data-revive-zoneid="zoneId" data-revive-target="_blank" data-revive-id="c780505d5ea85da2f3c3396fd3234b23" v-html="zoneContent"></ins>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "AdSection",
	components: {},
	props: {
		adtype: String,
		backgroundclass: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			winwidth: null,
			breakpoint: 1000,
			leaderboards: ['header','footer','midleader'],

			zoneContent: '',
		}
	},
	computed: {
		domain() {
			return this.store.domain;
		},
		skin() {
			return this.getSkin()
		},
		bgclass() {
			if(this.backgroundclass && this.backgroundclass.length > 0) return this.backgroundclass;
			return '';
		},
		ismobile() {
			if(this.winwidth < this.breakpoint) return true;
			return false;
		},
		adholderclass() {
			if(this.leaderboards.includes(this.adtype)) {
				if(this.ismobile) return 'mobile-leaderboard';
				else return 'desktop-leaderboard';
			}
			else if(this.adtype === 'body') {
				if(this.ismobile) return 'mobile-body';
				else return 'desktop-body';
			}
			return '';
		},
		zoneId() {
			//let dom = this.store.domain;
			let zones = this.store.globals.domainInfo.reviveZones;
			let zoneId = 0;
			if(this.adtype === 'header' && this.ismobile === false) zoneId = zones.header_desktop;
			else if(this.adtype === 'header' && this.ismobile === true) zoneId = zones.header_mobile;
			else if(this.adtype === 'footer' && this.ismobile === false) zoneId = zones.footer_desktop;
			else if(this.adtype === 'footer' && this.ismobile === true) zoneId = zones.footer_mobile;
			else if(this.adtype === 'body' && this.ismobile === false) zoneId = zones.body_desktop;
			else if(this.adtype === 'body' && this.ismobile === true) zoneId = zones.body_mobile;
			return zoneId;
		},
		vp() {
			return this.store.vuepage;
		},
		storeAdContent() {
			return this.store.adZoneContent;
		},
		emptyZoneClass() {
			if(this.zoneContent.length === 0) return '' //still loading
			else if(this.zoneContent.indexOf("width='0' height='0'") > -1) return 'emptyzone'
			else return ''
		}
	},
	methods: {

	},
	watch: {
		storeAdContent() {
			let myindex = this.store.adZones.indexOf(this.zoneId);
			if(this.store.adZoneContent[myindex]) {
				this.zoneContent = this.store.adZoneContent[myindex];
			}
		},
		$route() {
			this.zoneContent = '';
			if(this.zoneId != null && this.zoneId !== 0) {
				this.registerAdZone(this.zoneId);
			}
		},
	},
	mounted() {
		this.winwidth = window.innerWidth;
		if(this.zoneId != null && this.zoneId !== 0) {
			this.registerAdZone(this.zoneId);
		}

		// var e = window, a = 'inner';
		// if (!('innerWidth' in window )) {
		// 	a = 'client';
		// 	e = document.documentElement || document.body;
		// }
		// var f = { width : e[ a+'Width' ] , height : e[ a+'Height' ] };
		// this.winwidth = f.width;

	}
}
</script>

<style scoped>
.ad-section {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 45px;
	padding-bottom: 45px;
	position: relative;
}
.ad-section.header, .ad-section.footer {
	padding-top: 60px;
	padding-bottom: 60px;
}
.footer {
	background-color: #171717;
}
.ad-holder {
	z-index: 1;
}
.desktop-leaderboard {
	width: 728px;
	height: 90px;
}
.mobile-leaderboard {
	width: 320px;
	height: 50px;
}
.desktop-body {
	width: 970px;
	height: 250px;
}
.mobile-body {
	width: 300px;
	height: 250px;
}
/*.ad-section.emptyzone {
	height: 0;
	visibility: hidden;
	padding: 0;
}*/

@media screen and (max-width: 1200px) {

}
@media screen and (max-width: 1000px) {
	.ad-section.header, .ad-section.footer {
		padding-top: 45px;
		padding-bottom: 45px;
	}
}

</style>