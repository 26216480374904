<template>
	<div class="footer-section-holder">

		<AdSection adtype="footer"></AdSection>

		<div class="footer-main">
			<div class="container">
				<div class="footer-left">
					<div class="footer-logos-hold">
						<a class="footer-logo-left" href="super-radio-network">
							<img alt="Super Radio Network logo" class="logo-image" :src="store.urlroot+'/public-static/srn-logo.png?v=4'" />
						</a>
<!--						<div class="footer-logo-right">-->
<!--							<img :alt="store.globals.domainInfo.meta_title+' logo'" class="logo-image" :src="footerlogourl" />-->
<!--						</div>-->
					</div>
					<div class="footer-below-logos-text">
						<p>{{store.globals.domainInfo.meta_title}} is part of the Super Radio Network.</p>
					</div>
				</div>
				<div class="footer-right">
<!--					<div class="footer-right-col first-right-col">-->
<!--						<div class="menuitem">-->
<!--							<a href="/news" data-targpagetpl="aggregate-news">News</a>-->
<!--						</div>-->
<!--						<div class="menuitem">-->
<!--							<a href="/shows" data-targpagetpl="aggregate-shows">Shows</a>-->
<!--						</div>-->
<!--						<div class="menuitem">-->
<!--							<a href="/win" data-targpagetpl="aggregate-comps">Win</a>-->
<!--						</div>-->
<!--						<div class="menuitem">-->
<!--							<a href="/events" data-targpagetpl="aggregate-events"><span>Events</span></a>-->
<!--						</div>-->
<!--					</div>-->
					<div class="footer-right-col">
						<div class="menuitem">
							<a href="/privacy-policy" data-targpagetpl="simple-page">Privacy Policy</a>
						</div>
						<div class="menuitem">
							<a href="/general-terms-conditions" data-targpagetpl="simple-page">Competition T&Cs</a>
						</div>
						<div class="menuitem">
							<a href="/commercial-agreements" data-targpagetpl="simple-page">Commercial Agreements</a>
						</div>
						<div class="menuitem">
							<a href="/complaints">Complaints</a>
						</div>
<!--						<div class="menuitem">-->
<!--							<a href="/codes-of-conduct" data-targpagetpl="simple-page">Codes Of Conduct</a>-->
<!--						</div>-->
					</div>
					<div class="footer-right-col">
						<div class="menuitem" v-if="store.globals.advertisePage && store.globals.advertisePage.length > 0">
							<a :href="store.globals.advertisePage" data-targpagetpl="public-form">Advertise</a>
						</div>
						<div class="menuitem">
							<a href="/forms/contact-us" data-targpagetpl="public-form">Contact</a>
						</div>
						<div class="social-links">
							<a v-if="store.globals.domainInfo.facebook_link.length > 0" :href="store.globals.domainInfo.facebook_link" target="_blank">
								<i class="mdi mdi-facebook" style="font-size: 20px;"></i>
							</a>
							<a v-if="store.globals.domainInfo.instagram_link.length > 0" :href="store.globals.domainInfo.instagram_link" target="_blank">
								<i class="mdi mdi-instagram" style="font-size: 20px;"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-below">
			<div class="container">
				<div class="subfooter-left">
					<span>All rights reserved &copy;{{fullyear}}.  Super Radio Network. </span>
				</div>
				<div class="subfooter-right">

				</div>
			</div>
		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import AdSection from "@/components/AdSection";
export default {
	name: "FooterSection",
	components: {AdSection},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			fullyear: 2024,
		}
	},
	computed: {
		footerlogourl() {
			let filename = this.store.domain+'.svg?v=4';
			if(this.store.metroDomains.indexOf(this.store.domain) > -1) {
				filename = this.store.domain+'-footer.svg?v=4';
			}
			return this.store.urlroot+'/public-static/logos/'+filename;
		},
		isMetroDomain() {
			if (this.store.metroDomains.includes(this.store.domain)) return true;
			return false;
		},
	},
	methods: {},
	watch: {},
	mounted() {
		var d = new Date();
		this.fullyear = d.getFullYear();
	}
}
</script>

<style scoped>
.footer-main {
	color: #FFF;
	background-color: #000;
	padding-top: 80px;
	padding-bottom: 80px;
	width: 100%;
	font-family: var(--primaryfont);
}
.footer-main {
	background-size: auto 100%;
	background-position: top left;
	background-repeat: no-repeat;
}
.footer-main .container {
	display: flex;
	width: 100%;
	justify-content: space-between;
}
.footer-left {
	width: 49%;
}
.footer-right {
	margin-left: 40px;
	width: 49%;
	display: flex;
	justify-content: flex-end;
}
.footer-logos-hold {
	display: flex;
	justify-content: flex-start;
	gap: 20px;
	margin-bottom: 20px;
}
.footer-logo-left, .footer-logo-right {
	padding: 20px;
}
.footer-logo-left {
	/*border-right: 1px solid #4D4D4D;
	padding-right: 40px;*/
	padding-left: 0;
}
.logo-image {
	height: 90px;
	width: 200px;
	object-fit: contain;
}
.footer-right-col {
	width: 40%;
}
.menuitem {
	font-size: 14px;
	text-transform: uppercase;
	padding-bottom: 10px;
	transition: all 0.3s ease-in-out;
	font-weight: 500;
	letter-spacing: 0.05em;
}
.menuitem:hover {
	color: #999999;
}
.footer-below {
	padding-top: 20px;
	padding-bottom: 20px;
	font-family: var(--primaryfont);
	font-size: 13px;
	color: #FFF;
	background-color: #000;
}
.footer-below .container {
	display: flex;
	justify-content: space-between;
	padding-top: 20px;
	border-top: 1px solid #343434;
	padding-left: 0;
	padding-right: 0;
}
.social-links {
	display: flex;
	gap: 10px;
}


@media screen and (max-width: 1400px) {
	.footer-left {
		width: 35%;
		min-width: 400px;
	}
	.footer-right {
		width: 60%;
	}
}
@media screen and (max-width: 1150px) {
	.footer-right {
		flex-wrap: wrap;
	}
	.footer-right-col {
		width: 220px;
		justify-content: flex-end;
	}
	.footer-below .container {
		flex-wrap: wrap;
	}
	.subfooter-left, .subfooter-right {
		width: unset;
	}
	.subfooter-left {
		margin-bottom: 10px;
		margin-right: 10px;
	}
}
@media screen and (max-width: 950px) {
	.footer-left {
		min-width: 270px;
	}
}
@media screen and (max-width: 960px) {
	.footer-right {
		display: block;
		width: 200px;
	}
	.first-right-col, .footer-right-col {
		display: block;
		margin-bottom: 0;
		padding-bottom: 0;
	}
}
@media screen and (max-width: 768px) {
	.footer-below {
		padding-bottom: 100px; /* to handle mobile nav at bottom */
	}
	.footer-right {
		display: block;
		width: 50%;
		max-width: 240px;
	}
}
@media screen and (max-width: 600px) {
	.footer-main .container {
		flex-wrap: wrap;
	}
	.footer-left {
		width: 100%;
		min-width: unset;
	}
	.footer-right {
		width: 100%;
		max-width: unset;
		margin: 40px auto 0;
	}
	.footer-left, .footer-right, .footer-below {
		text-align: center;
	}
	.first-right-col, .footer-right-col {
		width: 100%;
	}
	.footer-below .container {
		display: block;
	}
	.footer-logos-hold {
		flex-wrap: wrap;
		justify-content: center;
	}
	.subfooter-right a {
		justify-content: center;
		margin-top: 20px;
	}
	.social-links {
		display: flex;
		gap: 10px;
		justify-content: center;
	}
}
@media screen and (max-width: 500px) {
	.footer-logos-hold {
		flex-direction: column;
		align-items: center;
	}
	.footer-logo-left {
		border: none;
		padding-left: 20px;
		padding-right: 20px;
	}
}
</style>