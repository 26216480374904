<template>
	<div class="part-srn-section" :class="bgclass">
		<a class="container" href="/super-radio-network" data-targpagetpl="about-supernetwork">
			<p class="partoftext">Part of the</p>
			<img :src="store.urlroot+'/public-static/srn-logo.png?v=4'" alt="SRN Logo" />
		</a>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "PartOfSRNSection",
	components: {},
	props: {
		backgroundclass: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

		}
	},
	computed: {
		domain() {
			return this.store.domain;
		},
		bgclass() {
			if(this.backgroundclass && this.backgroundclass.length > 0) return this.backgroundclass;
			return '';
		},
		vp() {
			return this.store.vuepage;
		},
	},
	methods: {

	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.part-srn-section {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 45px;
	padding-bottom: 45px;
	position: relative;
	background-color: #242424;
}
.part-srn-section .container {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #FFF;
	gap: 20px;
}
.partoftext {
	font-size: 17px;
	font-weight: 500;
}

@media screen and (max-width: 1200px) {

}
@media screen and (max-width: 599px) {
	.part-srn-section .container {
		flex-direction: column;
		gap: 10px;
	}
}

</style>