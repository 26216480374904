<template>
	<div>
		<DomSwitcher v-if="store.isDevServer === true && showDomSwitcher" @close="showDomSwitcher = false"></DomSwitcher>
		<div class="nav" :class="'d'+store.domain">
			<div class="left-background"></div>
			<div class="container">
				<div class="logohold">
					<a href="/" data-targpagetpl="home">
						<img :alt="store.globals.domainInfo.meta_title+' logo'" class="logo-image"
							:src="store.urlroot+'/public-static/logos/'+store.domain+'.png?v=4'"
						/>
					</a>
				</div>
				<div class="navright">
					<PlayWidget v-if="streamingLink && streamingLink.length > 0"></PlayWidget>
					<div class="menuitems">
						<div class="menuitem">
							<a href="/news" :class="{active : activePath === '/news'}" data-targpagetpl="aggregate-news">News</a>
						</div>
						<div class="menuitem">
							<a href="/shows" :class="{active : activePath === '/shows'}" data-targpagetpl="aggregate-shows">Shows</a>
						</div>
						<div v-if="displayWinLink" class="menuitem">
							<a href="/win" :class="{active : activePath === '/win'}" data-targpagetpl="aggregate-comps">Win</a>
						</div>
						<div class="menuitem">
							<a href="/events" :class="{active : activePath === '/events'}" data-targpagetpl="aggregate-events">Events</a>
						</div>
					</div>
					<div class="sidebar-toggle-hold">
						<div @click.stop="showSideMenu">
							<svg id="menu-toggle-svg" xmlns="http://www.w3.org/2000/svg" width="36" height="27" viewBox="0 0 36 27">
								<g id="Group_5" data-name="Group 5" transform="translate(-1824 -47)">
									<rect id="Rectangle_94" data-name="Rectangle 94" width="36" height="3" rx="1.5" transform="translate(1824 47)" fill="currentColor"/>
									<rect id="Rectangle_95" data-name="Rectangle 95" width="36" height="3" rx="1.5" transform="translate(1824 59)" fill="currentColor"/>
									<rect id="Rectangle_96" data-name="Rectangle 96" width="22" height="3" rx="1.5" transform="translate(1824 71)" fill="currentColor"/>
								</g>
							</svg>
						</div>
					</div>
				</div>
				<div id="sidebar-nav" class="sidebar-bg" :class="{shown : sidebarShown}">
					<div class="sidebar-body">
						<div @click.stop="hideSideMenu" class="sidebar-close-holder">
							<i class="mdi mdi-close text-white"></i>
						</div>

						<SearchBox></SearchBox>

						<div class="text-white" style="margin-top: 20px;">
							<a v-if="store.isDevServer" href="#" @click="showDomSwitcher = true; hideSideMenu()" class="side-menu-link tablet-only" data-targpagetpl="aggregate-news">Switch Domain</a>
							<a href="/news" class="side-menu-link tablet-only" data-targpagetpl="aggregate-news">News</a>
							<a href="/shows" class="side-menu-link tablet-only" data-targpagetpl="aggregate-shows">Shows</a>
							<a v-if="displayWinLink" href="/win" class="side-menu-link tablet-only" data-targpagetpl="aggregate-comps">Win</a>
							<a href="/events" class="side-menu-link tablet-only" data-targpagetpl="aggregate-events">Events</a>

							<a href="/super-radio-network" class="side-menu-link" data-targpagetpl="about-supernetwork">Super Radio Network</a>
							<a href="/forms/contact-us" class="side-menu-link" data-targpagetpl="public-form">Contact</a>
							<a v-if="store.globals.advertisePage && store.globals.advertisePage.length > 0" :href="store.globals.advertisePage" class="side-menu-link" data-targpagetpl="public-form">Advertise</a>
<!--							<a href="/forms/advertising-enquiry" class="side-menu-link" data-targpagetpl="public-form">Advertise</a>-->
							<a href="/forms/submit-event" class="side-menu-link" data-targpagetpl="public-form">Add An Event</a>
<!--	-						<a href="/codes-of-conduct" class="side-menu-link" data-targpagetpl="simple-page">Codes Of Conduct</a>-->
							</div>
					</div>

				</div>
			</div>
		</div>
		<div class="navpad"></div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import PlayWidget from "@/components/PlayWidget";
import SearchBox from "@/components/SearchBox.vue";
import DomSwitcher from "@/components/DomSwitcher.vue";
export default {
	name: "NavBar",
	components: {
		DomSwitcher,
		SearchBox,
		PlayWidget
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			showDomSwitcher: false,
		}
	},
	computed: {
		displayWinLink() {
			let wins = ['2hd','newfm']
			return wins.includes(this.store.domain)
		},
		activePath() {
			let p = this.$route.path
			if(p.substr(-1) === '/') { //remove trailing slash
				p = p.substr(0, p.length - 1)
			}
			return p
		},
		domain() {
			return this.store.domain;
		},
		skin() {
			return this.getSkin()
		},
		sidebarShown: {
			get() {
				return this.store.sideMenuIsOpen
			},
			set(val) {
				this.store.sideMenuIsOpen = val
			}
		},
		streamingLink() {
			return this.store.globals.domainInfo.streamingLink
		},
	},
	methods: {
		showSideMenu() {
			this.sidebarShown = true
		},
		hideSideMenu() {
			this.sidebarShown = false
		},
	},
	watch: {

	},
	mounted() {
		//document.getElementById('menusvg').src = '/public-static/hamburger.svg';
	}
}
</script>
<style>

</style>
<style scoped>
#sidebar-nav {
	position: fixed;
	top: 0;
	right: -320px;
	height: 100%;
	overflow-y: auto;
	width: 320px;
	transition: transform 0.3s ease-in-out;
}
.sidebar-bg {
	background-color: var(--primarycolor);
}
#sidebar-nav.shown {
	transform: translateX(-320px);
	box-shadow: 0 0 10px 3px rgb(0 0 0 / 20%);
}
.sidebar-close-holder {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	cursor: pointer;
}
.sidebar-bg {
	background-color: var(--primarycolor) !important;
}
.sidebar-body {
	width: 86%;
	margin: 10px auto;
	position: relative;
}
.nav {
	height: 120px;
	position: fixed;
	width: 100%;
	z-index: 20;
	background-color: var(--navbgcolor);
	box-shadow: 0 0 10px 3px rgb(0 0 0 / 20%);
}
.nav .container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	position: relative;
	background-color: var(--navbgcolor);
	padding-left: 0;
	padding-right: 0;
}
.left-background {
	width: 50%;
	height: 100%;
	position: absolute;
	background-color: var(--navlogobgcolor);
}
.navpad {
	height: 120px;
}
.logohold {
	background-color: var(--navlogobgcolor);
	max-width: 300px;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-right: 40px;
}
.on-air-now {
	text-align: right;
	width: 200px;
	padding: 5px 10px 0 10px;
	color: #FFF;
}
.on-air-now p {
	color: #FFF;
}
/** image sizings for logos **/
.logo-image {
	height: 70px;
	max-height: 70px;
}
.d2bh .logo-image {
	height: 100px;
	max-height: 100px;
}
/** END image sizings for logos **/
.navright {
	height: 100%;
	display: flex;
	align-items: center;
}
.menuitems {
	display: flex;
	height: 100%;
	align-items: center;
}
.menuitem {
	color: var(--navtextcolor);
	text-transform: uppercase;
	font-family: var(--primaryfont);
	font-size: 16px;
	font-weight: 500;
	height: 100%;
	position: relative;
	padding: 0 20px;
}
.sidebar-toggle-hold {
	color: var(--secondarycolor);
	cursor: pointer;
}
.menuitem a {
	height: 100%;
	display: flex;
	border-top: 4px solid transparent;
	align-items: center;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}
.menuitem a:hover, .menuitem a.active {
	border-top: 4px solid var(--navtextcolorhover);
	color: var(--navtextcolorhover);
}
.sidebar-toggle-hold > div {
	padding: 10px 0 10px 20px;
	transition: background-color 0.3s ease-in-out;
}
.sidebar-toggle-hold {
	margin-left: 80px;
}
.menu-ham-text {
	margin-right: 10px;
}
.menu-i {
	font-size: 30px;
}
.side-menu-link {
	display: block;
	padding: 10px 20px;
	font-family: var(--primaryfont);
	font-size: 15px;
	transition: all 0.2s ease-in-out;
	border-radius: 20px;
}
.side-menu-link:hover {
	background-color: var(--primarycolor);
	opacity: 0.7;
}
#menu-toggle-svg {
	height: 20px;
	color: var(--secondarycolor);
	margin-top: 6px;
}

@media screen and (max-width: 1350px) {
	.sidebar-toggle-hold {
		margin-left: 0;
	}
	.menuitem a, .sidebar-toggle-hold > div {
		padding: 10px 0 10px 15px;
	}
}
@media screen and (max-width: 1150px) {
	.on-air-now {
		display: none;
	}
}
@media screen and (max-width: 1000px) {
	.menu-ham-text {
		display: none;
	}
	.menu-i {
		font-size: 20px;
	}
}
@media screen and (max-width: 900px) {
	.menuitem {
		display: none;
	}
	.menu-ham-text {
		display: unset;
	}
	.menu-i {
		font-size: 14px;
	}
}
@media screen and (max-width: 768px) {
	.menu-ham-text {
		display: none;
	}
	.menu-i {
		font-size: 20px;
	}
}
@media screen and (max-width: 580px) {
	.nav {
		height: 70px;
		position: fixed;
		width: 100%;
		z-index: 20;
	}
	.navpad {
		height: 70px;
	}
	.logohold {
		max-width: 200px;
	}
	/** image sizings for logos **/
	.logo-image {
		height: 50px;
		max-height: 50px;
	}
	/** END image sizings for logos **/
}
</style>
