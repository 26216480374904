<template>
	<div class="submit-form-section">
		<div class="container">
			<div class="form-holder">
				<form @submit="submitForm">
					<div class="form-item-hold">
						<input class="form-input" type="text" v-model="formdata.title" placeholder="Title/Heading of event" />
						<p class="invalid-feedback-manual" v-if="nameState === false">{{nameInvalidFeedback}}</p>
					</div>

					<div class="form-item-hold">
						<input class="form-input" type="text" v-model="formdata.subheading" placeholder="Subheading (optional)" />
					</div>

					<div class="form-item-hold">
						<p class="date-label">Event Start Date</p>
						<date-picker class="dpick" v-model:value="formdata.event_start_date" valueType="format" format="YYYY-MM-DD"></date-picker>
						<p class="invalid-feedback-manual" v-if="startDateState === false">{{dateInvalidFeedback}}</p>
					</div>

					<div class="form-item-hold">
						<p class="date-label">Event End Date</p>
						<date-picker class="dpick" v-model:value="formdata.expiration_date" valueType="format" format="YYYY-MM-DD"></date-picker>
						<p class="invalid-feedback-manual" v-if="endDateState === false">{{dateInvalidFeedback}}</p>
					</div>

					<div class="form-item-hold">
						<input class="form-input" type="text" v-model="formdata.event_time" placeholder="Start & End time of event" />
						<p class="invalid-feedback-manual" v-if="timeState === false">{{timeInvalidFeedback}}</p>
					</div>

					<div class="form-item-hold">
						<input class="form-input" type="text" v-model="formdata.location_town" placeholder="Event Location Town" />
						<p class="invalid-feedback-manual" v-if="locTownState === false">{{nameInvalidFeedback}}</p>
					</div>

					<div class="form-item-hold">
						<textarea v-model="formdata.location_address" id="event_details" placeholder="Event Location Address" rows="6"></textarea>
						<p class="invalid-feedback-manual" v-if="locAddrState === false">{{msgInvalidFeedback}}</p>
					</div>

					<div class="form-item-hold">
						<textarea v-model="formdata.event_details" id="event_details" placeholder="Event Details (nb. if this is a recurring event please specify)" rows="6"></textarea>
						<p class="invalid-feedback-manual" v-if="msgState === false">{{msgInvalidFeedback}}</p>
					</div>

					<div class="form-item-hold">
						<input class="form-input" type="text" v-model="formdata.event_link" placeholder="Event Website (if there is one)" />
					</div>

					<div class="form-item-hold">
						<div>
							<p class="date-label">Image for use on event page listing (jpg, png, gif)</p>
							<input type="file" @change="handleFile" id="inputFileElement" />
						</div>
					</div>

					<div class="form-item-hold">
						<input class="form-input" type="text" v-model="formdata.event_contact_name" placeholder="Contact Name (contact details will not be published)" />
						<p class="invalid-feedback-manual" v-if="contactNameState === false">{{nameInvalidFeedback}}</p>
					</div>

					<div class="form-item-hold">
						<input class="form-input" type="text" v-model="formdata.event_contact_phone" placeholder="Contact Phone" />
						<p class="invalid-feedback-manual" v-if="phoneState === false">{{phoneInvalidFeedback}}</p>
					</div>

					<div class="form-item-hold">
						<input class="form-input" type="email" v-model="formdata.event_contact_email" placeholder="Contact Email Address" />
						<p class="invalid-feedback-manual" v-if="emailState === false">{{emailInvalidFeedback}}</p>
					</div>

					<input type="hidden" id="robots" ref="robots" v-model="robots" />
				</form>

				<div class="button-holder">
					<div v-if="submissionComplete === false" class="primary-button bgdark textwhite" v-on:click="submitForm" v-bind:class="{'button-is-working':(formworking === true)}">
						<span>Submit</span>
						<WorkingButtonInsert v-if="formworking === true"></WorkingButtonInsert>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import WorkingButtonInsert from "@/components/WorkingButtonInsert";
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css';
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "SubmitEventForm",
	mixins: [globalMixin],
	components: {
		WorkingButtonInsert,
		DatePicker,
	},
	props: {
		page: Object,
	},
	data: function () {
		return {
			store: store,
			checksTriggered: false,
			formworking: false,
			submissionComplete: false,
			formdata: {
				title: '',
				subheading: '',
				event_start_date: '',
				expiration_date: '',
				event_time: '',
				location_town: '',
				location_address: '',
				event_details: '',
				event_link: '',
				event_image: '',
				event_contact_name: '',
				event_contact_phone: '',
				event_contact_email: '',
			},
			imageuploadfile: null,
			robots: '',
		}
	},
	computed: {
		formName() {
			return this.page.name;
		},
		nameState() {
			if(this.checksTriggered === false && this.formdata.title.length < 2) {
				return null;
			}
			else if(this.formdata.title.length >= 2) {
				return true;
			}
			return false;
		},
		nameInvalidFeedback() {
			return 'Please enter something.'
		},
		timeState() {
			if(this.checksTriggered === false && this.formdata.event_time.length < 2) {
				return null;
			}
			else if(this.formdata.event_time.length >= 2) {
				return true;
			}
			return false;
		},
		timeInvalidFeedback() {
			return "Please enter a valid time";
		},
		startDateState() {
			if(this.checksTriggered === false) {
				return null;
			}
			else if(this.formdata.event_start_date.length >= 10) {
				return true;
			}
			return false;
		},
		endDateState() {
			if(this.checksTriggered === false) {
				return null;
			}
			else if(this.formdata.expiration_date.length >= 10) {
				return true;
			}
			return false;
		},
		dateInvalidFeedback() {
			return "Please select a date";
		},
		locTownState() {
			if(this.checksTriggered === false) {
				return null;
			}
			else if(this.formdata.location_town.length >= 3) {
				return true;
			}
			return false;
		},
		locAddrState() {
			if(this.checksTriggered === false) {
				return null;
			}
			else if(this.formdata.location_address.length >= 3) {
				return true;
			}
			return false;
		},
		contactNameState() {
			if(this.checksTriggered === false) {
				return null;
			}
			else if(this.formdata.event_contact_name.length >= 3) {
				return true;
			}
			return false;
		},
		emailState() {
			if(this.checksTriggered === false && this.formdata.event_contact_email.length >= 0 && /.+@.+\..+/.test(this.formdata.event_contact_email) === false) {
				return null;
			}
			else if(this.formdata.event_contact_email.length >= 0 && /.+@.+\..+/.test(this.formdata.event_contact_email)) {
				return true;
			}
			return false;
		},
		emailInvalidFeedback() {
			return 'Please enter a valid email address.'
		},
		phoneState() {
			if(this.checksTriggered === false && this.formdata.event_contact_phone.length < 6) {
				return null;
			}
			else if(this.formdata.event_contact_phone.length >= 6) {
				return true;
			}
			return false;
		},
		phoneInvalidFeedback() {
			return 'Please enter a valid phone number.'
		},
		msgState() {
			if(this.checksTriggered === false && this.formdata.event_details.length === 0) {
				return null;
			}
			else if(this.formdata.event_details.length > 1000 || this.formdata.event_details.length === 0) {
				return false;
			}
			return true;
		},
		msgInvalidFeedback() {
			return 'Please enter a message. Max 1000 characters.';
		},
	},
	methods: {
		handleFile(evt) {
			if(evt.target.files.length) {
				this.imageuploadfile = evt.target.files[0]
			}
		},
		submitForm() {
			if(this.formworking === false) { //only proceed if not already waiting on callback
				this.checksTriggered = true; //check for validations
				var checkitems = [
					this.nameState, this.timeState, this.startDateState, this.endDateState,
					this.locTownState, this.locAddrState, this.contactNameState, this.emailState,
					this.phoneState, this.msgState
				];
				for(var i=0; i<checkitems.length; i++) {
					if(checkitems[i] === false) return false;
				}
				if(this.robots.length > 0) return false;
				this.formworking = true; //make button working class
				var form = new FormData();
				form.append('formdata', JSON.stringify(this.formdata));
				form.append('domain', this.store.domain);
				form.append('formname', this.formName);
				if(this.imageuploadfile !== null) {
					form.append('file-upload', this.imageuploadfile, this.imageuploadfile.name);
				}
				var self = this;
				axios.post("/post/submit-event-form.php", form).then(function(response) {
					var responsejson = response.data; //response.data is returned info
					self.formworking = false;
					if(responsejson.error === 1) {
						self.showKalert(responsejson);
					}
					else if(responsejson.success === 1) {
						self.showKalert(responsejson);
						self.submissionComplete = true;
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>
<style scoped>
.submit-form-section {
	background-color: #E8E8E8;
}
.date-label {
	font-size: 12px;
	margin: 15px 0 5px;
}
.button-holder {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
}
</style>