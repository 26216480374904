<template>
	<div class="hero-section">
		<div v-if="store.loading === false" class="container structure">
			<div>
				<h1 class="text-white">{{title}}</h1>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "HeroSectionTitleOnly",
	components: {

	},
	props: {
		title: String,
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {

	},
	methods: {},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.hero-section {
	height: 250px;
	max-height: 250px;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: var(--primarycolor);
	background: linear-gradient(to right, var(--primarydark), var(--primarycolor));
}
.container {
	max-height: 100%;
}
h1 {
	font-family: var(--primaryfont);
	text-align: center;
	font-weight: 500;
}
.structure {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	height: 100%;
}
.left-half {
	width: 49%;
}

@media screen and (max-width: 959px) {

}
@media screen and (max-width: 640px) {
	.hero-section {
		height: 200px;
		max-height: 200px;
		position: relative;
	}
	.left-half {
		width: 100%;
		text-align: center;
	}
}
</style>