<template>
	<div id="app">

		<AlertTop :obj="store.kalert"></AlertTop>

		<div v-if="firstloadok" id="" :class="skin +' '+ store.domain + ' d'+store.domain">

			<!-- global nav goes here -->
			<NavBar></NavBar>

			<!-- router view handles template view pages content -->
			<router-view></router-view>

			<!-- global footer goes here -->

		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from 'axios';
import NavBar from "@/components/NavBar";
import AlertTop from "@/components/AlertTop";

export default {
	name: 'App',
	components: {
		AlertTop,
		NavBar
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			firstloadok: false,
			root: null,


		}
	},
	computed: {
		slotprops() {
			return this.store.vuepage.slotprops;
		},
		settings() {
			return this.store.globals.settings;
		},
		loading() {
			return this.store.loading;
		},
		skin() {
			return '' //this.getSkin()
		},
		pagetemplate() {
			return this.store.pagetemplate;
		},
		domainShortcode() {
			return this.store.domain
		},
	},
	methods: {
		removeTrailingSlash(incoming) {
			var lastchar = incoming.slice(-1);
			if(lastchar === "/") {
				incoming = incoming.slice(0,-1);
			}
			return incoming;
		},
		fetchPageJSON(relurl) {
			this.store.loading = true
			this.store.sideMenuIsOpen = false
			this.store.adZones = []
			this.store.adZoneContent = []
			window.scroll({top: 0, left: 0, behavior: 'instant'}); //window.scroll for animation, window.scrollTo for no animation
			var href = this.store.urlroot + relurl;
			var self = this;
			href = this.removeTrailingSlash(href);
			axios.get(href+"/?ajax=1&dom="+self.store.domain).then(function(response) {
				var ret = response.data; //response.data is returned info
				if(ret.error === 1) { console.log(ret); }
				document.title = ret.documentTitle;
				self.setVuepage(ret);
				self.store.pagetitle = ret.pageTitle
				self.store.pagetemplate = ret.pageTemplate
				self.store.pagename = ret.name
				self.store.loading = false
				document.querySelector('head meta[name="description"]').setAttribute('content', ret.metaDescription);
				document.querySelector('head meta[name="keywords"]').setAttribute('content', ret.metaKeywords);
				// window.ga('set','page', href); //old GA method for analytics
				// window.ga('send','pageview');
				if(window.gtag) { //new method if needed
					window.gtag('config', window.gtagua, {'page_path': href}); //gtag GA method for analytics
				}
				self.aceAnalyticsSend(ret.fromCache)
				self.firstloadok = true;
				setTimeout(function() { //call the ads - give Vue time to catch up with render after page content is received
					self.updateAds();
				}.bind(self), 100);
			}).catch(function(error) {
				console.log(error);
				var ret = error.response.data; //error.response.data is returned info
				console.log(ret);
				document.title = ret.documentTitle;
				self.setVuepage(ret);
				self.store.pagetitle = ret.pageTitle
				self.store.pagetemplate = ret.pageTemplate
				self.store.pagename = ret.name
				self.store.loading = false
				self.firstloadok = true;
			});
		},
		updateAds() {
			if(this.store.adZones.length > 0) {
				let zonesneed = encodeURIComponent(this.store.adZones.join("|"));
				let myloc = encodeURIComponent(this.store.urlroot + this.$route.path);
				let getinf = 'https://srn.ace-adserver.com/www/delivery/asyncspc.php?zones=' + zonesneed + '&prefix=revive-0-&target=_blank&loc=' + myloc;
				let self = this;
				axios.get(getinf).then(function (response) {
					let ret = response.data; //response.data is returned info
					self.setAdContent(ret);
				});
			}
		},
		aceAnalyticsSend(fromCache) {
			let screenwidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
			//check if cookie exists
			let aceSessionId = ''
			let cookies = document.cookie.split(";")
			for (let i = 0; i < cookies.length; i++) {
				let cookie = cookies[i].split("=")
				if (cookie[0].trim() == "aceSessionId") {
					aceSessionId = cookie[1]
				}
			}

			let xhttp = new XMLHttpRequest()
			let sob = {
				url: window.location.href,
				getparams: window.location.search,
				fromCache: fromCache,
				screenwidth: screenwidth,
				aceSessionId: aceSessionId
			}
			xhttp.open("POST", "https://analytics.aceradio.com.au/analytics.php", true)
			xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
			xhttp.send(JSON.stringify(sob))
			//handle response
			xhttp.onreadystatechange = function() {
				if (this.readyState == 4 && this.status == 200) {
					let response = JSON.parse(this.responseText)
					if(response.sessionId){
						let cookieExpireTime = "Fri, 31 Dec 9999 23:59:59 GMT"
						if(response.sessionCookieExpire) {
							cookieExpireTime = response.sessionCookieExpire
						}
						else {
							let d = new Date()
							d.setTime(d.getTime() + (30*60*1000))
							cookieExpireTime = d.toUTCString()
						}
						document.cookie = "aceSessionId=" + response.sessionId + "; expires="+cookieExpireTime+"; SameSite=None; Secure; path=/"
					}
				}
			}
		},
		updateColorTheme() {
			if(this.domainShortcode) {
				if(this.domainShortcode === '2sm') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#DC2924')
					this.root.style.setProperty("--primarycolor",'#DC2924')
					this.root.style.setProperty("--primarydark",'#C70500')
					this.root.style.setProperty("--secondarycolor",'#DC2924')
				}
				else if(this.domainShortcode === '2hd') {
					this.root.style.setProperty("--navbgcolor",'#0093D9')
					this.root.style.setProperty("--navlogobgcolor",'#0093D9')
					this.root.style.setProperty("--navtextcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolorhover",'#FEF205')
					this.root.style.setProperty("--primarycolor",'#0093D9')
					this.root.style.setProperty("--primarydark",'#027BB5')
					this.root.style.setProperty("--secondarycolor",'#FEF205') //hamburger
				}
				else if(this.domainShortcode === 'brock') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#4E4E4F')
					this.root.style.setProperty("--navtextcolorhover",'#464646')
					this.root.style.setProperty("--primarycolor",'#464646')
					this.root.style.setProperty("--primarydark",'#212121')
					this.root.style.setProperty("--secondarycolor",'#D6212B') //hamburger
				}
				else if(this.domainShortcode === '2bs') {
					this.root.style.setProperty("--navbgcolor",'#242424')
					this.root.style.setProperty("--navlogobgcolor",'#242424')
					this.root.style.setProperty("--navtextcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolorhover",'#BFA447')
					this.root.style.setProperty("--primarycolor",'#BFA447')
					this.root.style.setProperty("--primarydark",'#A27D30')
					this.root.style.setProperty("--secondarycolor",'#A37E31') //hamburger
				}
				else if(this.domainShortcode === 'newfm') {
					this.root.style.setProperty("--navbgcolor",'#ED273B')
					this.root.style.setProperty("--navlogobgcolor",'#ED273B')
					this.root.style.setProperty("--navtextcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolorhover",'#000000')
					this.root.style.setProperty("--primarycolor",'#ED273B')
					this.root.style.setProperty("--primarydark",'#D22838')
					this.root.style.setProperty("--secondarycolor",'#000000') //hamburger
				}
				else if(this.domainShortcode === '2du') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#FF1B18')
					this.root.style.setProperty("--primarycolor",'#FF1B18')
					this.root.style.setProperty("--primarydark",'#FF1B18')
					this.root.style.setProperty("--secondarycolor",'#FE1B16') //hamburger
				}
				else if(this.domainShortcode === '2bh') {
					this.root.style.setProperty("--navbgcolor",'#000000')
					this.root.style.setProperty("--navlogobgcolor",'#000000')
					this.root.style.setProperty("--navtextcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolorhover",'#F6A017')
					this.root.style.setProperty("--primarycolor",'#F6A017')
					this.root.style.setProperty("--primarydark",'#F68717')
					this.root.style.setProperty("--secondarycolor",'#F6F40B') //hamburger
				}
				else if(this.domainShortcode === '2el') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#02AF84')
					this.root.style.setProperty("--primarycolor",'#02AF84')
					this.root.style.setProperty("--primarydark",'#008867')
					this.root.style.setProperty("--secondarycolor",'#02AF84') //hamburger
				}
				else if(this.domainShortcode === '2gf') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#656565')
					this.root.style.setProperty("--primarycolor",'#656565')
					this.root.style.setProperty("--primarydark",'#000000')
					this.root.style.setProperty("--secondarycolor",'#FFD511') //hamburger
				}
				else if(this.domainShortcode === '2hc') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#F81C11')
					this.root.style.setProperty("--primarycolor",'#F81C11')
					this.root.style.setProperty("--primarydark",'#BC0800')
					this.root.style.setProperty("--secondarycolor",'#F81C11') //hamburger
				}
				else if(this.domainShortcode === '2lf') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#4E4E4E')
					this.root.style.setProperty("--primarycolor",'#4E4E4E')
					this.root.style.setProperty("--primarydark",'#343434')
					this.root.style.setProperty("--secondarycolor",'#000000') //hamburger
				}
				else if(this.domainShortcode === '2mg') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#F30017')
					this.root.style.setProperty("--primarycolor",'#F30017')
					this.root.style.setProperty("--primarydark",'#F30017')
					this.root.style.setProperty("--secondarycolor",'#F30017') //hamburger
				}
				else if(this.domainShortcode === '2mo') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#0061B4')
					this.root.style.setProperty("--primarycolor",'#0061B4')
					this.root.style.setProperty("--primarydark",'#0000B1')
					this.root.style.setProperty("--secondarycolor",'#0102D2') //hamburger
				}
				else if(this.domainShortcode === '2nz') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#D83FFC')
					this.root.style.setProperty("--primarycolor",'#D83FFC')
					this.root.style.setProperty("--primarydark",'#9800BF')
					this.root.style.setProperty("--secondarycolor",'#D63FFF') //hamburger
				}
				else if(this.domainShortcode === '2pk') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#2441FF')
					this.root.style.setProperty("--primarycolor",'#2441FF')
					this.root.style.setProperty("--primarydark",'#2441FF')
					this.root.style.setProperty("--secondarycolor",'#2441FF') //hamburger
				}
				else if(this.domainShortcode === '2re') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#A5D0C1')
					this.root.style.setProperty("--primarycolor",'#A5D0C1')
					this.root.style.setProperty("--primarydark",'#A5D0C1')
					this.root.style.setProperty("--secondarycolor",'#A5D0C1') //hamburger
				}
				else if(this.domainShortcode === '2tm') {
					this.root.style.setProperty("--navbgcolor",'#195FDB')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolorhover",'#FFFFFF')
					this.root.style.setProperty("--primarycolor",'#195FDB')
					this.root.style.setProperty("--primarydark",'#003DA8')
					this.root.style.setProperty("--secondarycolor",'#FFFFFF') //hamburger
				}
				else if(this.domainShortcode === '2vm') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolorhover",'#D11012')
					this.root.style.setProperty("--primarycolor",'#D11012')
					this.root.style.setProperty("--primarydark",'#A00003')
					this.root.style.setProperty("--secondarycolor",'#D11014') //hamburger
				}
				else if(this.domainShortcode === '4ak') {
					this.root.style.setProperty("--navbgcolor",'#801784')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolorhover",'#9A199F')
					this.root.style.setProperty("--primarycolor",'#9A199F')
					this.root.style.setProperty("--primarydark",'#4F0052')
					this.root.style.setProperty("--secondarycolor",'#FE7F00') //hamburger
				}
				else if(this.domainShortcode === '4gy') {
					this.root.style.setProperty("--navbgcolor",'#019ED6')
					this.root.style.setProperty("--navlogobgcolor",'#019ED6')
					this.root.style.setProperty("--navtextcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolorhover",'#F5EA1E')
					this.root.style.setProperty("--primarycolor",'#019ED6')
					this.root.style.setProperty("--primarydark",'#019ED6')
					this.root.style.setProperty("--secondarycolor",'#F5EA1E') //hamburger
				}
				else if(this.domainShortcode === '4wk') {
					this.root.style.setProperty("--navbgcolor",'#231F20')
					this.root.style.setProperty("--navlogobgcolor",'#231F20')
					this.root.style.setProperty("--navtextcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolorhover",'#EF1923')
					this.root.style.setProperty("--primarycolor",'#393939')
					this.root.style.setProperty("--primarydark",'#231F20')
					this.root.style.setProperty("--secondarycolor",'#EF1923') //hamburger
				}
				else if(this.domainShortcode === '8ha') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#3B59F2')
					this.root.style.setProperty("--primarycolor",'#3B59F2')
					this.root.style.setProperty("--primarydark",'#051A8B')
					this.root.style.setProperty("--secondarycolor",'#031B8C') //hamburger
				}
				else if(this.domainShortcode === 'fm929') {
					this.root.style.setProperty("--navbgcolor",'#000000')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolorhover",'#FF7C31')
					this.root.style.setProperty("--primarycolor",'#FF7C31')
					this.root.style.setProperty("--primarydark",'#EF6A1E')
					this.root.style.setProperty("--secondarycolor",'#F06A20') //hamburger
				}
				else if(this.domainShortcode === 'rokfm') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#454545')
					this.root.style.setProperty("--primarycolor",'#454545')
					this.root.style.setProperty("--primarydark",'#212121')
					this.root.style.setProperty("--secondarycolor",'#F01426') //hamburger
				}
				else if(this.domainShortcode === 'radio97') {
					this.root.style.setProperty("--navbgcolor",'#000000')
					this.root.style.setProperty("--navlogobgcolor",'#000000')
					this.root.style.setProperty("--navtextcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolorhover",'#FDE000')
					this.root.style.setProperty("--primarycolor",'#4D4D4D')
					this.root.style.setProperty("--primarydark",'#1A1A1A')
					this.root.style.setProperty("--secondarycolor",'#FDE000') //hamburger
				}
				else if(this.domainShortcode === 'fm1047') {
					this.root.style.setProperty("--navbgcolor",'#3689B1')
					this.root.style.setProperty("--navlogobgcolor",'#3689B1')
					this.root.style.setProperty("--navtextcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolorhover",'#FFD511')
					this.root.style.setProperty("--primarycolor",'#46AFAB')
					this.root.style.setProperty("--primarydark",'#3689B1')
					this.root.style.setProperty("--secondarycolor",'#FFD511') //hamburger
				}
				else if(this.domainShortcode === '2lm') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#009F7C')
					this.root.style.setProperty("--primarycolor",'#009F7C')
					this.root.style.setProperty("--primarydark",'#009F7C')
					this.root.style.setProperty("--secondarycolor",'#72FEEB') //hamburger
				}
				else if(this.domainShortcode === 'fm1003') {
					this.root.style.setProperty("--navbgcolor",'#F17A20')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolorhover",'#FFFFFF')
					this.root.style.setProperty("--primarycolor",'#F17A20')
					this.root.style.setProperty("--primarydark",'#EB6500')
					this.root.style.setProperty("--secondarycolor",'#FFFFFF') //hamburger
				}
				else if(this.domainShortcode === 'radio531') {
					this.root.style.setProperty("--navbgcolor",'#471557')
					this.root.style.setProperty("--navlogobgcolor",'#471557')
					this.root.style.setProperty("--navtextcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolorhover",'#FCEF05')
					this.root.style.setProperty("--primarycolor",'#7C3691')
					this.root.style.setProperty("--primarydark",'#471557')
					this.root.style.setProperty("--secondarycolor",'#FCEF05') //hamburger
				}
				else if(this.domainShortcode === 'gemfm') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#000079')
					this.root.style.setProperty("--primarycolor",'#000079')
					this.root.style.setProperty("--primarydark",'#000012')
					this.root.style.setProperty("--secondarycolor",'#000079') //hamburger
				}
				else if(this.domainShortcode === 'hillfm') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#FCB513')
					this.root.style.setProperty("--primarycolor",'#CFD0D1')
					this.root.style.setProperty("--primarydark",'#666769')
					this.root.style.setProperty("--secondarycolor",'#FCB513') //hamburger
				}
				else if(this.domainShortcode === 'maxfm') {
					this.root.style.setProperty("--navbgcolor",'#1F1F27')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolorhover",'#0090B5')
					this.root.style.setProperty("--primarycolor",'#0090B5')
					this.root.style.setProperty("--primarydark",'#0D1D36')
					this.root.style.setProperty("--secondarycolor",'#02AFDC') //hamburger
				}
				else if(this.domainShortcode === 'nowfm') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#FE5B05')
					this.root.style.setProperty("--primarycolor",'#FE5B05')
					this.root.style.setProperty("--primarydark",'#D80102')
					this.root.style.setProperty("--secondarycolor",'#FCB513') //hamburger
				}
				else if(this.domainShortcode === 'realfm') {
					this.root.style.setProperty("--navbgcolor",'#191919')
					this.root.style.setProperty("--navlogobgcolor",'#191919')
					this.root.style.setProperty("--navtextcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolorhover",'#F30017')
					this.root.style.setProperty("--primarycolor",'#797979')
					this.root.style.setProperty("--primarydark",'#2C2C2C')
					this.root.style.setProperty("--secondarycolor",'#F30017') //hamburger
				}
				else if(this.domainShortcode === 'roccyfm') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#FD6F04')
					this.root.style.setProperty("--primarycolor",'#FD6F04')
					this.root.style.setProperty("--primarydark",'#FD6F04')
					this.root.style.setProperty("--secondarycolor",'#FD6F04') //hamburger
				}
				else if(this.domainShortcode === 'sunfm') {
					this.root.style.setProperty("--navbgcolor",'#000000')
					this.root.style.setProperty("--navlogobgcolor",'#000000')
					this.root.style.setProperty("--navtextcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolorhover",'#9D1684')
					this.root.style.setProperty("--primarycolor",'#9D1684')
					this.root.style.setProperty("--primarydark",'#37002E')
					this.root.style.setProperty("--secondarycolor",'#FFFFFF') //hamburger
				}
				else if(this.domainShortcode === 'zoofm') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#FFA81D')
					this.root.style.setProperty("--primarycolor",'#FFA81D')
					this.root.style.setProperty("--primarydark",'#CB6900')
					this.root.style.setProperty("--secondarycolor",'#EB8518') //hamburger
				}
				else if(this.domainShortcode === 'tripleg') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#904FBE')
					this.root.style.setProperty("--primarycolor",'#904FBE')
					this.root.style.setProperty("--primarydark",'#7030A0')
					this.root.style.setProperty("--secondarycolor",'#702F9F') //hamburger
				}
				else if(this.domainShortcode === 'zzzfm') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#606060')
					this.root.style.setProperty("--primarycolor",'#606060')
					this.root.style.setProperty("--primarydark",'#272727')
					this.root.style.setProperty("--secondarycolor",'#FE1923') //hamburger
				}
				else if(this.domainShortcode === '4am') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#F8A231')
					this.root.style.setProperty("--primarycolor",'#F8A231')
					this.root.style.setProperty("--primarydark",'#05ACAA')
					this.root.style.setProperty("--secondarycolor",'#02ADAB') //hamburger
				}
				else if(this.domainShortcode === '4kz') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#004389')
					this.root.style.setProperty("--primarycolor",'#DB2124')
					this.root.style.setProperty("--primarydark",'#DB2124')
					this.root.style.setProperty("--secondarycolor",'#004389') //hamburger
				}
				else if(this.domainShortcode === '2ad') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#FF5F5F')
					this.root.style.setProperty("--primarycolor",'#FF5F5F')
					this.root.style.setProperty("--primarydark",'#C40003')
					this.root.style.setProperty("--secondarycolor",'#0000FE') //hamburger
				}
				else if(this.domainShortcode === 'koolfm') {
					this.root.style.setProperty("--navbgcolor",'#FFFFFF')
					this.root.style.setProperty("--navlogobgcolor",'#FFFFFF')
					this.root.style.setProperty("--navtextcolor",'#000000')
					this.root.style.setProperty("--navtextcolorhover",'#007AEB')
					this.root.style.setProperty("--primarycolor",'#007AEB')
					this.root.style.setProperty("--primarydark",'#013F88')
					this.root.style.setProperty("--secondarycolor",'#C71519') //hamburger
				}
			}
		},
	},
	mounted() {
		this.root = document.documentElement //for CSS root overrides
		if(this.firstloadok) {
			this.store.pagetemplate = window.vuepage.pageTemplate
			this.store.pagetitle = window.vuepage.pageTitle
			this.store.pagename = window.vuepage.name
			this.pagepathmutation(this.$route.path);
			this.aceAnalyticsSend(window.vuepage.fromCache);
		}
		this.updateColorTheme()
		//handle clicks in inline content (ie not proper vue-router links)
		window.addEventListener('click', event => {
			let {target} = event; // ensure we use the link, in case the click has been received by a sub-element
			while (target && target.tagName !== 'A') target = target.parentNode
			// handle only links that do not reference external resources
			if (target && target.matches("a:not([href*='://'])") && target.href) {
				//some sanity checks taken from vue-router: https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
				const {altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented} = event;
				if (metaKey || altKey || ctrlKey || shiftKey) return; // don't handle with control keys
				if (defaultPrevented) return; // don't handle when preventDefault called
				if (button !== undefined && button !== 0) return; //don't handle right clicks
				let linkPageTemplate = ""; //try to get the next page template to show skeleton loader whilst we're waiting on ajax
				if (target && target.getAttribute) { // don't handle if `target="_blank"`
					const linkTarget = target.getAttribute('target');
					if (/\b_blank\b/i.test(linkTarget)) return;
					linkPageTemplate = target.getAttribute('data-targpagetpl');
				}
				const url = new URL(target.href); //don't handle same page links/anchors
				const to = url.pathname
				if(window.location.pathname === to) { //fix bug where some page redirects to itself (reloads page)
					event.preventDefault();
					return false;
				}
				if (window.location.pathname !== to && event.preventDefault) {
					event.preventDefault();
					this.store.pagetemplate = linkPageTemplate //will be blank if unknown
					this.$router.push(to);
				}
			}
		});
	},
	watch: {
		$route(to) {
			this.store.loading = true
			this.pagepathmutation(to.path);
			this.fetchPageJSON(this.store.pagepath);
		},
		domainShortcode() {
			this.updateColorTheme()
		},
		// firstloadok() {
		// 	if(this.firstloadok === true) {
		// 		console.log(this.store.vuepage.slotprops)
		// 	}
		// },
	},
	created() { //used to call ajax on first load if required
		if(window.vuepage.pagetemplate === undefined) {
			//this.fetchPageJSON(this.$route.path);
		}
		else {
			this.firstloadok = true;
		}
	},
};
</script>

<style>
#app {
}
</style>
